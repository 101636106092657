
import firebase from "firebase/app";
import "firebase/storage";
import MButton from "@/components/form/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MTextInputModal from "@/components/MTextInputModal.vue";
import {
  getThreadsOfStudent,
  putThread,
  complementThreads,
  createNotificationForTutors
} from "@/api/thread";
import { postFile, deleteFile, postFileAsUploadTask } from "@/api/storage";
import { Student } from "@/entities/student";
import {
  safeFileTypes,
  getValuesFromFileMetadata
} from "@/entities/submission";
import { RelatedLink } from "@/entities/submission_master";
import { ComplementedThread, ComplementedThreadPost } from "@/entities/thread";
import store, { awaitStudentLoaded } from "@/store";
import { Options, Vue } from "vue-class-component";
import dayjs from "dayjs";
import Compressor from "compressorjs";
import mime from "mime";

@Options({
  components: {
    MButton,
    MIcon,
    MTextInputModal
  }
})
export default class ThreadView extends Vue {
  dataMap: ComplementedThread | null = null;
  posts: ComplementedThreadPost[] = [];
  imageUrlsRequiringAltDisplay: string[] = []; // Storage上に画像がない場合 URL をこちらに格納し、代替表示を行う
  threadName = "";
  threadLabel = "";
  description = "";
  comment = "";
  relatedLinks: RelatedLink[] = [];
  student: Student | null = null;
  showCommentModal = false;

  get isAndroid() {
    // 撮影ボタンを Android のみ表示する
    return store.state.isAndroid;
  }

  get hasPreviousPage() {
    // 通知メッセージから直接この画面を開いた場合、戻り先のページがない
    return history.length > 1;
  }

  get imagePosts() {
    return this.posts.filter(n => n.type === "image");
  }
  get filePosts() {
    return this.posts.filter(n => n.type === "file");
  }

  get isValidData() {
    return this.posts.length > 0;
  }

  async imageUpload(event: Event) {
    if (
      !(event.target instanceof HTMLInputElement) ||
      !event.target.files ||
      event.target.files.length === 0 ||
      !store.state.schoolDocId ||
      !store.state.classroomDocId ||
      !this.student ||
      !this.dataMap
    ) {
      return;
    }
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "画像をアップロード中...");

    const now = dayjs();
    const submittedAt = now.format("YYYY-MM-DD-HH-mm-ss");
    const promises: Promise<ComplementedThreadPost>[] = [];

    for (let i = 0, l = event.target.files.length; i < l; i++) {
      const file = event.target.files[i];
      if (!safeFileTypes.includes(file.type)) {
        store.commit("SET_LOADING", false);
        alert(`次の種類のファイルは提出できません。\n【${file.type}】`);
        continue;
      }
      const directory = `submissions/schools/${store.state.schoolDocId}/classrooms/${store.state.classroomDocId}/students/${this.student.ref.id}/threads/${this.dataMap.ref.id}/`;
      const fileName = `${this.dataMap.threadMasterRef?.id ?? ""}_${
        this.dataMap.ref.id
      }_${submittedAt}_${i}`;

      // 軽量化してアップロードする
      promises.push(
        new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.5,
            convertSize: 300 * 1000, // 300KB 以上の png は jpg に変換する
            success(result) {
              const fileToUpload = result as File;
              // jpg に変換されている可能性があるので、ここで拡張子を決定する
              const ext = "." + fileToUpload.name.split(".").pop();

              postFile(fileToUpload, {
                path: directory + fileName + ext
              })
                .then(({ downloadURL }: { downloadURL: string }) => {
                  resolve({
                    type: "image",
                    time: now.unix(),
                    url: downloadURL,
                    value: "",
                    contentTypeToDisplay: "",
                    contentIconType: "",
                    sizeString: ""
                  });
                })
                .catch(reject);
            },
            error: reject
          });
        })
      );
    }
    await Promise.all(promises)
      .then(newNodes => {
        if (!this.dataMap) throw new Error("非同期処理中の例外的なエラー"); // 通常考えられない、ビルドを通すために必要
        putThread(this.dataMap.ref, {
          posts: [...this.posts, ...newNodes]
        });
        this.posts = [...this.posts, ...newNodes];
      })
      .catch(e => {
        alert(`ファイルの提出時にエラーが発生しました。\n\n${e.message}`);
      });
    store.commit("SET_LOADING", false);
  }

  async freeFormatFileUpload(event: Event) {
    if (
      !(event.target instanceof HTMLInputElement) ||
      !event.target.files ||
      event.target.files.length === 0 ||
      !store.state.schoolDocId ||
      !store.state.classroomDocId ||
      !this.student ||
      !this.dataMap
    ) {
      return;
    }
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "ファイルをアップロード中...");

    const now = dayjs();
    const submittedAt = now.format("YYYY-MM-DD-HH-mm-ss");

    const file = event.target.files[0];
    const directory = `submissions/schools/${store.state.schoolDocId}/classrooms/${store.state.classroomDocId}/students/${this.student.ref.id}/threads/${this.dataMap.ref.id}/`;
    const fileName = `${this.dataMap.threadMasterRef?.id}_${this.dataMap.ref.id}_file_${submittedAt}`;

    new Promise<firebase.storage.Reference>((resolve, reject) => {
      const ext = mime.getExtension(file.type)
        ? `.${mime.getExtension(file.type)}`
        : "";
      postFileAsUploadTask(file, {
        path: `${directory}${fileName}${ext}`,
        onStateChanged: summary => {
          store.commit(
            "SET_LOAD_TEXT",
            `アップロード中 : ${summary.transferredPercentage} / 100 % 完了`
          );
        },
        onError: reject,
        onComplete: resolve
      });
    })
      .then(async fileRef => {
        const downloadURL = await fileRef.getDownloadURL();
        const { contentType, size } = await fileRef.getMetadata();
        if (!this.dataMap) throw new Error("非同期処理中の例外的なエラー"); // 通常考えられない、ビルドを通すために必要
        putThread(this.dataMap.ref, {
          posts: [
            ...this.posts,
            {
              type: "file",
              time: now.unix(),
              url: downloadURL,
              value: ""
            }
          ]
        });
        const { contentTypeToDisplay, contentIconType, sizeString } =
          getValuesFromFileMetadata(size, contentType);
        this.posts = [
          ...this.posts,
          {
            type: "file",
            time: now.unix(),
            url: downloadURL,
            value: contentTypeToDisplay,
            contentTypeToDisplay,
            contentIconType,
            sizeString
          }
        ];
        store.commit("SET_LOADING", false);
        store.commit("SET_LOAD_TEXT", "");
      })
      .catch(e => {
        alert(`ファイルの提出時にエラーが発生しました。\n\n${e.message}`);
        store.commit("SET_LOADING", false);
        store.commit("SET_LOAD_TEXT", "");
      });
  }

  async deleteImage(url: string) {
    if (!this.dataMap) return;
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "画像を削除中...");
    const imageRef = firebase.storage().refFromURL(url);
    try {
      const newNodes = this.posts.filter(n => n.url !== url);
      putThread(this.dataMap.ref, {
        posts: newNodes
      });
      this.posts = newNodes;
      if (!this.imageUrlsRequiringAltDisplay.includes(url))
        await deleteFile(imageRef);
    } catch (e) {
      alert(`画像の削除に失敗しました。\n\n${e.message}`);
    } finally {
      store.commit("SET_LOADING", false);
    }
  }

  async deleteFreeFormatFile(url: string) {
    if (!this.dataMap) return;
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "ファイルを削除中...");
    const fileRef = firebase.storage().refFromURL(url);
    try {
      const newNodes = this.posts.filter(n => n.url !== url);
      putThread(this.dataMap.ref, {
        posts: newNodes
      });
      this.posts = newNodes;
      await deleteFile(fileRef);
    } catch (e) {
      alert(`ファイルの削除に失敗しました。\n\n${e.message}`);
    } finally {
      store.commit("SET_LOADING", false);
    }
  }

  setAltImage(url: string) {
    this.imageUrlsRequiringAltDisplay = [
      url,
      ...this.imageUrlsRequiringAltDisplay
    ];
  }

  goBack() {
    this.$router.go(-1);
  }

  openCommentModal() {
    this.showCommentModal = true;
  }

  updateComment(next: string) {
    this.comment = next;
  }

  async saveComment(next: string) {
    if (!this.dataMap) return;
    // 期限なしの場合、post の一種類として先生へのコメントを考える
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "コメントを保存中...");
      await putThread(this.dataMap.ref, {
        posts: next
          ? [
              ...this.posts.filter(p => p.type !== "text"),
              {
                value: next,
                time: dayjs().unix(),
                url: "",
                type: "text"
              }
            ]
          : this.posts.filter(p => p.type !== "text")
      });
      this.comment = next;
    } catch (e) {
      alert(`先生へのコメントが正しく保存できませんでした。`);
    } finally {
      store.commit("SET_LOADING", false);
    }
  }

  async sendMessage() {
    if (!this.isValidData || !this.student || !this.dataMap) return;
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "先生にメッセージを送信中...");
      await createNotificationForTutors(this.student.ref, {
        title: `${this.student.data.name}が${this.threadName}にファイルを追加しました`,
        content: this.comment ? `【先生へのコメント】\n\n${this.comment}` : "",
        threadDocId: this.dataMap.ref.id
      });
      alert(`🙆‍♂️ 提出状況を先生に報告しました📣 おつかれさま😀`);
      this.$router.replace({
        path: "/submission/"
      });
    } catch (e) {
      alert(`提出完了の処理中にエラーが発生しました。`);
    } finally {
      store.commit("SET_LOADING", false);
    }
  }

  async created() {
    this.student = await awaitStudentLoaded(store);
    const threadId = this.$route.params.threadId as string;
    if (!threadId) {
      return;
    }
    const threads = await getThreadsOfStudent(this.student.ref, {
      threadId
    });
    if (!threads?.[0]) return;
    const complemented = (await complementThreads(
      threads
    )) as ComplementedThread[];
    this.dataMap = complemented[0];
    if (!this.dataMap?.threadMasterData) return;

    this.posts = this.dataMap.data.posts;
    this.threadName = this.dataMap.threadMasterData.name;
    this.threadLabel = this.dataMap.threadMasterData.label;
    this.description = this.dataMap.threadMasterData.description ?? "";
    this.relatedLinks = this.dataMap.threadMasterData.relatedLinks ?? [];

    const textPost = this.posts.find(p => p.type === "text");
    this.comment = textPost ? textPost.value : "";
  }
}
